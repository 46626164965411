export default {
  videos: [],
  projects: [],
  selected_ids: [],
  vdo_errors: {
    vdo_name: [],
    vdo_timeline: [],
    ident_id: [],
    global: [],
  },
  remainingCredit : 0
}