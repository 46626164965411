import axios from "axios";
import I18n from "../../../i18n";

import Hotjar from '@hotjar/browser';
import { Crisp } from "crisp-sdk-web";

//ROUTER
import router from "./../../../router/index";
import parseJwt from "../../../lib/parseJwt";


const state = {
  token: null,
  tokenDecoded: {},
  user: {},
  admin_usr_changer: null,
  admin_usr_id_changer: null,
};

const mutations = {
  SET_TOKEN: (state, token) => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    state.token = token;

    state.tokenDecoded = token != null ? parseJwt(token) : {};
  },
  SET_USER_INFO: (state, usr_info) => {
    state.user = usr_info;

    if (process.env.NODE_ENV == "production") {

      //UDPATE HOTJAR INFO
      const hj_siteId = 3520917;
      const hotjarVersion = 6;
      try {
        Hotjar.init(hj_siteId, hotjarVersion);
        Hotjar.identify(state.user._id, {
          email : state.user.usr_email
        })
      } catch (e){
        console.warn(e)
      }

      try {
        //UPDATE CRIPS INFO
        Crisp.user.setEmail(state.user.usr_email);
        Crisp.user.setNickname(`${state.user.usr_firstname} ${state.user.usr_lastname}`);
        //Crisp.user.setPhone(state.user.usr_phone);
        Crisp.user.setCompany(state.user.usr_company);
        Crisp.session.setData({
          yopbox_user_id : state.user._id,
          stripe_id : state.user.usr_customer_stripe_id,
          subscription_name : state.user.usr_subscription.name || "Free",
        });
      } catch (e){
        console.warn(e)
      }
    }

  },
  SET_USER_ID: (state, new_id) => {
    state.user._id = new_id;
  },
  SET_USER_ADMIN_CHANGER: (state, user) => {
    state.admin_usr_changer = user;
    state.admin_usr_id_changer = user._id;
  },
};

const getters = {
  getToken: (state) => state.token,
};

const actions = {
  //ADMIN CAN CHANGE USER ACCOUNT :
  //        WILL STORE ID IN state.admin_usr_id_changer .
  //             state.admin_usr_id_changer will be use in priority if his value is not null
  set_usr_id_admin_changer: ({ commit }, new_id) => {
    return axios.get("/users/" + new_id).then((res) => {
      commit("SET_USER_ADMIN_CHANGER", res.data);
    });
  },

  login: ({ commit }, { email, password, team_id, query }) => {
    return axios
      .post("/auth", {
        usr_email: email,
        usr_pwd: password,
        team_id: team_id,
      })

      .then((res) => {
        commit("SET_TOKEN", res.data.token);
        commit("SET_USER_INFO", res.data.usr_info);
          
        router.push({
          //name: "Home"
          path: "/",
        });
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err.response);
      });
  },
  setToken: ({ commit }, { token, usr_info, query }) => {
    commit("SET_TOKEN", token);
    commit("SET_USER_INFO", usr_info);

    router.push({
      name: "Home",
      query: query,
    });
  },
  verifyAndStoreTokenFromCookie: (
    { commit, dispatch, state },
    tokenFromCookie
  ) => {
    //1. Ping api to get usr_id (/auth/ping)
    return axios
      .get(`/auth/ping`, {
        headers: {
          Authorization: `Bearer ${tokenFromCookie}`,
        },
      })
      .then((res) => {
        //console.log(res)
        //2. Storing token cookie from in state
        commit("SET_TOKEN", res.data);
        //3. Set usr_id in store and "refresh_user_info"
        commit("SET_USER_ID", parseJwt(res.data).usr_id);

        return dispatch("refresh_user_info");
      })
      .then(() => {
        //dispatch('loadProjects')
        //dispatch('loadVideos')
      })
      .catch(() => {
        //Storing token cookie =null in state
        commit("SET_TOKEN", null);
      });
  },
  register: (
    { commit },
    {
      email,
      password,
      firstname,
      lastname,
      //phone,
      organization,
      language,
      team_id,
      query,
    }
  ) => {

    return axios.post("/auth/register", {
      usr_email: email,
      usr_pwd: password,
      usr_firstname: firstname,
      usr_lastname: lastname,
      //usr_phone: phone,
      usr_company: organization,
      usr_language: language,
      team_id: team_id,
    });
  },
  forgotPwd: ({ commit }, email) => {
    var self = this;
    return axios
      .post("/auth/forgot", {
        usr_email: email,
      })
      .then((res) => {
        window.Toaster.info(I18n.global.t("Login.newPassSent"));
        //window.Toaster.info(res.data)
        return Promise.resolve();
      })
      .catch((err) => {
        window.Toaster.error(I18n.global.t("Error_page.headline"));
        return Promise.reject();
      });
  },
  refresh_user_info: ({ state, commit }) => {
    return axios.get(`/users/${state.user._id}`).then((res) => {
      commit("SET_USER_INFO", res.data);

      return Promise.resolve();
    });
  },

  //CLEANE TOKEN
  clean_Token_And_Usr_Info: ({ state }) => {
    state.token = null;
    state.user = {};
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
