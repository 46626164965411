import { createRouter, createWebHistory } from 'vue-router'
import inApp_routes from './inApp'
import inApp_mobile_routes from './inApp_Mobile'

import store from "./../store";


//import domain_customization_global from '../domain_customization.js'
//const domain_customization = domain_customization_global[process.env.domain_name];

//LIBS
import i18n from '../i18n.js';

import {default as navigationVersion} from "../lib/navigatorVersion"

//PARSE COOKIE FUNCTION
const parseCookie = require('cookie-parse').parse;

const routes = [
  ...inApp_routes,
  ...inApp_mobile_routes,
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Bienvenu sur chez Yopbox',
      mobileCompatible : true
    },
    component: () => import('../views/Login/Login.vue'),

    beforeEnter: async (to, from, next) => {
      
      const parsedCookie = parseCookie(document.cookie) || {}
      if (parsedCookie.token != undefined && parsedCookie.token != null && store.getters['getToken'] == null){
        await store.dispatch('verifyAndStoreTokenFromCookie', parsedCookie.token)

        //If token was validated
        if (store.getters['getToken'] != null && store.getters['getToken'] != "null"){
          //console.debug('Token already valide')
          next({ name: 'inApp' })
        } else {
          next()
        }
      } else {
        next()
      }

    }
  },
  {
    path: '/share/:short_id',
    name: 'share',
    meta: {
      title: 'Bienvenu sur chez Yopbox',

      mobileCompatible : true
    },
    component: () => import('../views/Share/Share.vue'),
  },
  {
    path: '/getStarted',
    name: 'getStarted',
    meta: {
      title: 'Get Started 🎬',
      requiresAuth : true,
      mobileCompatible : true
    },
    component: () => import('../views/GetStarted/GetStarted.vue'),
  },

  
  { 
    path: "/:catchAll(.*)", 
    beforeEnter: (to, from, next) => {
      next({ name: 'Home' })
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active", //add "active" class on <router-link> current active
})



//auTH VERFICATION
//REDIRECT TO DESKTOP VERSION FOR NON MOBILE NAVIGATOR
router.beforeEach(async (to, from, next) => {
  //console.log(to.name)
  if (to.path == "/profile" || to.path == "/profile/") {
    next({
      name: "Profile.info",
    });
    return;
  }

  //REDIRECT MOBILE/DESKTOP VERSION
  //If user on mobile want to access to route non-mobile compatible, redirect to "/"
  if (navigationVersion.mobile == true && to.meta.mobileCompatible != true){
    console.info(`Page ${to.path} not mobile-compatible`)
    
    if (to.path.split("/").includes('edit')){
      next({
        name : "Creative Space - Mobile",
        params : to.params,
        query : to.query
      })
    } else {
      next({
        name : "Home - Mobile",
        query : to.query
      })
    }

    return;
  }
  

  //console.log(to.meta.requiresAuth)
  if (to.meta.requiresAuth != undefined && to.meta.requiresAuth == true){
    
    //ROLE VERIFICATION
    var parsedCookie = parseCookie(document.cookie) || {}
    if (parsedCookie.token != undefined && parsedCookie.token != null && store.getters['getToken'] == null){
      await store.dispatch('verifyAndStoreTokenFromCookie', parsedCookie.token)
    };



    //AFTER CHECKING AND VERIFY TOKEN IN COOKIE, 
    //IF USER HAVE INVALIDE TOKEN => REDIRECT TO SCRIPT
    if (store.getters['getToken'] == null || store.getters['getToken'] == "null"){
      console.debug('Token no valide')
      next({ name: 'Login' });
      return;
    };

    //REDIRECT TO GET STARTED PAGE
    const conditionsArray = [
      (store.getters['isSubscribed'] == false),
      (to.path.split("/").includes('getStarted') == false),
      (store.getters['isAdmin'] == false),
      (process.env.domain_name == "convinceo")
    ]

    if (!conditionsArray.includes(false)) {
      console.log('Redirect to getStarted')
      next({ name: 'getStarted' })
      
    //IF USER HAVE VALIDE TOKEN...
    } else if (to.meta.required_role != undefined) {
      if (store.getters['isAdmin']) {
        next()
      } else if (store.getters['getCurrentUser'].usr_role == to.meta.required_role) {
        next()
      } else {
        window.Toaster.error("Vous n'avez pas les autorisations necessaire")
        next({ name: 'Home' })
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router