var verifGlobal = {
   isEmpty(value){
   
       if (value == "" || value == undefined ){
          
           return true 
       }    else{
           return false
       }
   },
    notAlphabetical(value){
    
       let regex = new RegExp(/^[a-zàäâéèêëïîöôùüû\s ,.'-]+$/i)
       return !regex.test(value)
       
   },
  syntaxEmail(email)
   {
        return email.includes("@")
        
   }
    
    
}
    
export default verifGlobal
