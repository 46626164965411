export default {
  getVideos: (state) => () => {
    return state.videos;
  },
  vdoHasSubitles: (state) => (id) => {
    let video = state.videos.find((vdo) => vdo._id == id);
    if (video.vdo_result.subtitles != undefined) {
      return true;
    } else {
      return false;
    }
  },
};
