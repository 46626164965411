import Vuex from 'vuex'

import videos from "./modules/videos"
import auth from "./modules/auth"
import user from "./modules/user"
import timelineTemplate from './modules/timelineTemplate';
import projects from './modules/projects';
import creativeSpace from './modules/creativeSpace';
import onboarding from './modules/onboarding';

export default new Vuex.Store({

  modules: {
    auth,
    user,
    videos,
    timelineTemplate,
    projects,
    creativeSpace,
    onboarding
  }
})