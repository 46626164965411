module.exports = {
  yopbox: {
    serviceName: "Yopbox",
    color1: "#3eb1c8",
    color2: "#3d545c",
    logo_path: "yopbox_logo_white.svg",
    full_logo_color_path: "logo_yopbox_color.svg",
    api_base_url: {
      developpement: "https://api-dev.yopbox.com",
      production: "https://api-prod.yopbox.com",
    },
    recorder_url: {
      developpement: "https://rec-shadow.yopbox.com",
      production: "https://rec.yopbox.com"
    },
    token_cookie_domain: ".yopbox.com",
  },
  convinceo: {
    serviceName: "Convinceo",
    color1: "#5CC7AF", //Vert
    color2: "#0170b9", //Bleu
    logo_path: "convinceo_favicon_white.png",
    full_logo_color_path: "convinceo_logo_color.png",
    api_base_url: {
      developpement: "https://api-prod.yopbox.com",
      production: "https://api-prod.yopbox.com",
    },
    recorder_url: {
      developpement: "https://rec-shadow.yopbox.com",
      production: "https://rec-shadow.yopbox.com"
    },
    token_cookie_domain: ".yopbox.com",
  }
};
