import axios from "axios";
import I18n from "../../../i18n";

const state = {};
const mutations = {};
const actions = {
  openBillingPortal: ({ rootState }) => {
    let usr_id =
      rootState.auth.admin_usr_id_changer == null
        ? rootState.auth.user._id
        : rootState.auth.admin_usr_id_changer;
    axios
      .get(`/users/${usr_id}/billing-portal`)
      .then((res) => {
        if (res.data && res.data.url) {
          document.location.href = res.data.url;
        } else {
          window.Toaster.error(I18n.global.t("Toaster.error.billing_portal"));
        }
      })
      .catch(() => {
        window.Toaster.error(I18n.global.t("Toaster.error.billing_portal"));
      });
  },

  updateCurrentUser: ({ commit, rootState }, data) => {
    let usr_id =
      rootState.auth.admin_usr_id_changer == null
        ? rootState.auth.user._id
        : rootState.auth.admin_usr_id_changer;

    axios
      .put(`/users/${usr_id}`, data)
      .then((res) => {
        commit("SET_USER_INFO", res.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  },
};

const getters = {
  //USER IS ADMIN
  isAdmin: (state, getters, rootState) =>
    rootState.auth.tokenDecoded.usr_role == 0 ? true : false,

  //USER HAVE A SUBSCRIPTION
  isSubscribed: (state, getters, rootState) => {
    return rootState.auth.tokenDecoded.isSubscribed;
  },
  subscriptionInfos: (state, getters) => {
    let user = getters.getCurrentUser;
    return {
      one_time_credits : user.usr_one_time_credits,
      type: user.usr_subscription.name,
      recurring_credits : user.usr_recurring_credits,
      credits: user.usr_credits,
      paymentFailed : user.usr_subscription.paymentFailed

    };
  },
  getCurrentUser: (state, getters, rootState) => rootState.auth.admin_usr_changer == null ? rootState.auth.user : rootState.auth.admin_usr_changer,
  getCurrentUsrId: (state, getters, rootState) => rootState.auth.admin_usr_id_changer == null ? rootState.auth.user._id : rootState.auth.admin_usr_id_changer


};

export default {
  state,
  mutations,
  getters,
  actions,
};
