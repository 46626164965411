export default {
    getProjects: (state) => (vdo_state_or_list_id)=>{
        if (Array.isArray(vdo_state_or_list_id)){
            return state.projects.filter(project => vdo_state_or_list_id.includes(project._id))
        }else {
            return state.projects
        }
    },
    getProject: (state) => (project_id)=>{
        if (project_id){
            return state.projects.find(project => project._id == project_id)
        } else {
            return null
        }
    },
}