import { createApp } from 'vue'
import VueCookies from 'vue3-cookies'

import VuePlyr from "./components/Object/vue-plyr"


//import './components/Object/vue-plyr/dist/vue-plyr.css'
import I18n from './i18n.js'

import yopboxuikit from '@yopbox/yopboxuikit/library.mjs'

import { default as pjson } from '../package.json';

import domain_customization from './domain_customization.js'


import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

// AXIOS
import axios from 'axios'
import VueAxios from 'vue-axios'
axios.defaults.baseURL = process.env.API_URL

import router from './router'

import store from './store'
//APP
import App from './App.vue'
document.title = 'App Yopbox'

Array.prototype.forEachAsync = async function (fn) {
  var i = 0;
  for (let t of this) {
    i++;
    await fn(t, i - 1);
  }
};

const app = createApp(App)
app.use(I18n)
app.use(router)
app.use(store)
app.use(VueAxios, axios)
app.use(VueCookies, { domain: '.yopbox.com'})

app.component("v-select", vSelect);

app.use(VuePlyr, {
  plyr: {}
})
app.use(yopboxuikit)

app.mixin({
  //GLOBAL DATA
  data() {
    return {  
      API_URL: (() => {
        var url = process.env.API_URL;
        
        if (url.charAt(url.length - 1) == "/") {
          return url.slice(0, url.length - 1)
        } else {
          return url
        }
      })(),
      app_version: pjson.version,
      TOKEN_COOKIE_DOMAIN : process.env.TOKEN_COOKIE_DOMAIN,
      domain_customization : domain_customization[process.env.domain_name]
    }
  }
})
app.mount('#app')