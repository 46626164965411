export default [
    {
        path: '/',
        //name: 'Home - Mobile',
        meta: {
            title: 'App Yopbox',
            requiresAuth : true,
            mobileCompatible : true
        },
        component: () => import('../views/inApp_mobile.vue'),
        children: [
            {
              path: '/',
              name: 'Home - Mobile',
              meta: {
                title: 'App Yopbox - Accueil',
                requiresAuth : true,
                mobileCompatible : true
              },
              component: () => import('../views/Home_mobile/HomeMobile.vue'),
            },
            {
              path: '/edit/:project_id',
              name: 'Creative Space - Mobile',
              meta: {
                title: 'App Yopbox - Creative Space',
                requiresAuth : true,
                mobileCompatible : true
              },
              component: () => import('../views/CreativeSpace_mobile/CreativeSpaceMobile.vue'),
            }
        ]
    }
]