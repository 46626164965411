import axios from "axios";

//STATE
import state from "./state";

//LOCALES
import i18n from "../../../i18n";
const I18n = i18n.global;

export default {
  addProject: ({ commit, rootState }, projectData) => {
    //USER ID TO USE
    let usr_id_request = null;
    if (rootState.auth.admin_usr_id_changer != null) {
      usr_id_request = rootState.auth.admin_usr_id_changer;
    } else if (rootState.auth.user && rootState.auth.user._id) {
      usr_id_request = rootState.auth.user._id;
    }
    if (usr_id_request != null) {
      return axios
        .post("/projects", {
          usr_id: usr_id_request,
          project_name: "",
          ...projectData,
        })
        .then((res) => {
          commit("ADD_PROJECT", res.data);
          //commit('VERIF_ERRORS', state.projects.map(project => { return project._id }))
          return res.data;
        })
        .catch((err) => {
          var errorMsg = null;
          if (err && err.response && err.response.data) {
            if (err.response.data == "Project name must be unique") {
              errorMsg = I18n.t("Home.error.unique_project_name");
            }
          }

          if (errorMsg == null) {
            errorMsg = I18n.t("Toaster.error.add");
          }

          window.Toaster.error(errorMsg);

          return Promise.reject(errorMsg);
        });
    }
  },
  loadProjects: ({ commit, rootState }, all) => {
    if (all == undefined) {
      all = false;
    }
    let usr_id_request;
    if (rootState.auth.admin_usr_id_changer != null) {
      usr_id_request = rootState.auth.admin_usr_id_changer;
    } else if (rootState.auth.user && rootState.auth.user._id) {
      usr_id_request = rootState.auth.user._id;
    }

    if (all == true) {
      //GET ALL VIDEOS
      return axios
        .get("/projects/", { params: { media_url: true } })
        .then((res) => {
          commit("SET_PROJECTS", res.data);
          return res.data;
        });
    } else if (usr_id_request != null) {
      return axios
        .get("/projects/", {
          params: { usr_id: usr_id_request, media_url: true },
        })
        .then((res) => {
          commit("SET_PROJECTS", res.data);
          return res.data;
        });
    }
  },
  updateProject: ({ commit }, data) => {
    var url = "/projects/";
    url +=
      typeof data.update.ids == "string" ? data.update.ids : data.update.ids[0];
    if (data.queryParams) {
      url += `?${Object.keys(data.queryParams).map((key, keyIndex) => {
        if (keyIndex < Object.keys(data.queryParams).length - 1) {
          return key + "=" + data.queryParams[key] + "&";
        } else {
          return key + "=" + data.queryParams[key];
        }
      })}`;
    }
    return axios
      .put(url, data.update)
      .then((res) => {
        commit("PUT_PROJECT", {
          res: res.data,
          collect_objs: data.collect_objs,
        });
        // commit('VERIF_ERRORS', state.videos.map(video => { return video._id }));
        //commit('SET_SELECTED_VDO', [])

        // LINK WITH CREATIVE SPACE //
        //IF project update is loaded in creative space, update it in creative space store state
        //commit('MATCH_PROJECT_ID_AND_MERGE_DATA', data.update, { root: true })

        return res.data;
      })
      .catch((err) => {
        var errorMsg = null;
        if (err && err.response && err.response.data) {
          if (err.response.data == "Project name must be unique") {
            errorMsg = I18n.t("Home.error.unique_project_name");
          }
        }

        window.Toaster.error(errorMsg);

        return Promise.reject(errorMsg);
      });
  },
  deleteProject: ({ commit }, project_id) => {
    return axios.delete("/projects/" + project_id).then(() => {
      commit("DELETE_PROJECT", project_id);
    });
  },
  unsetField_project: ({ commit }, data) => {
    commit("UNSET_FIELD_PROJECT", data);
  },
  duplicateProject: ({ commit, rootState }, project_id) => {
    //USER ID TO USE
    let usr_id_request = null;
    if (rootState.auth.admin_usr_id_changer != null) {
      usr_id_request = rootState.auth.admin_usr_id_changer;
    } else if (rootState.auth.user && rootState.auth.user._id) {
      usr_id_request = rootState.auth.user._id;
    }

    return axios
      .post(`/projects/${project_id}/duplicate`)
      .then((res) => {
        commit("ADD_PROJECT", res.data);
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.errorCode == "E0202") {
          window.Toaster.warn(
            I18n.t("Toaster.warn.all_medias_are_not_optimized")
          );
        } else {
          window.Toaster.error(I18n.t("Home.error.duplication"));
        }
        return Promise.reject();
      });
  },
};
