/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import axios from "axios";

import i18n from "../../../i18n";
const I18n = i18n.global;

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export default {
  /* -------------- PROJECT -------------- */
  loadProject: async ({ state }, project_id) => {
    try {
      const res = await axios({
        method: "get",
        url: `/projects/${project_id}`,
      });
      state.project_data = res.data;
      state.project_loaded = true;
      return await Promise.resolve(state.project_data);
    } catch (err) {
      //window.Toaster.error(I18n.t("Toaster.error.dataRecovery"));
      throw err;
    }
  },
  unloadProject: ({ commit }) => {
    commit("CLEAR_PROJECT");
  },

  putProjectData: ({ state, dispatch, commit }, data) => {
    commit("SAVING_MODE", true);

    let sentData = data;
    //ENSURE DELETE music_order
    // Because music_order have to be juste in read_only for app. Just the api can modify it.
    delete sentData.musics_order;

    //SAVE STATE (FOR INSTANT ACTUALIZATION)

    //commit("UPDATE_PROJECT", sentData);

    //IF IDENT_ID GIVED, UPDATE IT

    //UPDATE ON BACKEND
    return axios({
      method: "put",
      url: `/projects/${state.project_data._id}`,
      data: sentData,
    })
      .then(async (res) => {
        if (state.part_expanded != null && state.part_expanded != -1) {
          const resClip = await axios.get(
            `/projects/${state.project_data._id}/timeline/${
              res.data.project_timeline[state.part_expanded]._id
            }`
          );
          res.data.project_timeline[state.part_expanded] = resClip.data;
        }
        commit("UPDATE_PROJECT", res.data);
        if (data.ident_id != null) {
          state.project_data.identity = data.identity;
          dispatch("updateAnimationsCustom");
        }

        commit("SAVING_MODE", false);
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        window.Toaster.error(I18n.t("Toaster.error.update"));
      });
  },
  /* ----------------- CLIP ------------------*/
  putClipData: ({ state, commit }, data) => {
    return new Promise(async (resolve, reject) => {
      if (data._id == undefined || data._id == null) {
        reject("No clip id gived : ");
      } else {
        var clip_id = data._id;
      }

      delete data._id;
      commit("SAVING_MODE", true);

      //UPDATE ON BACKEND
      var res = await axios({
        method: "put",
        url: `/projects/${state.project_data._id}/timeline/${clip_id}`,
        data: data,
      }).catch((err) => {
        console.log(err);
        window.Toaster.error(I18n.t("Toaster.error.update"));
        resolve();
      });

      //console.log(res.data.transcription[49])

      commit("UPDATE_CLIP", res.data);
      //Update animation with new content;

      if (
        res.data.clip_type == 0 &&
        res.data.anim_custom_id != null &&
        data.title_content != null
      ) {
        axios.put("/animations/" + res.data.anim_custom_id, {
          ident_id: state.project_data.ident_id,
          text: res.data.title_content,
        });
      }

      commit("SAVING_MODE", false);
      resolve(res.data);
    });
  },

  updateAnimationsCustom: ({ commit, state, dispatch }) => {
    let promiseList = [];

    let project_timeline = state.project_data.project_timeline;
    let oldScript = state.oldScript;

    project_timeline.forEach((part, newIdx) => {
      promiseList.push(
        new Promise((resolve) => {
          if (
            part.clip_type == 0 &&
            part.anim_type == 1 &&
            part.anim_custom_id != null
          ) {
            let idx = oldScript.findIndex((p) => p._id == part._id);

            if (
              idx == -1 ||
              (idx != -1 && oldScript[idx].title_content != part.title_content)
            ) {
              axios
                .put("/animations/" + part.anim_custom_id, {
                  ident_id: state.project_data.identity._id,
                  text: part.title_content,
                })
                .then((res) => {
                  if (
                    state.part_expanded == newIdx &&
                    part.title_content != ""
                  ) {
                    commit("SET_EXPANDED_ANIM", res.data);
                  }
                  resolve();
                });
            } else {
              resolve();
            }
          } else {
            resolve();
          }
        })
      );
    });

    return Promise.all(promiseList).then(() => {
      return Promise.resolve();
    });
  },

  update_script_order_w_group_linked_parts: ({ commit, dispatch }, script) => {
    const set = script.flatMap((clip_group) => {
      if (clip_group.group == true) {
        return clip_group.list;
      } else {
        return clip_group;
      }
    });
    commit("UPDATE_PROJECT", { project_timeline: set }); //HAVE TO UPDATE STATE BEFORE PUT ON API TO PREVENT GLITCH AT END OF DRAG-N-DROP
    dispatch("putProjectData", { timeline_order: set.map((c) => c._id) });
  },

  iAmExpanded: async ({ commit, state }, index) => {
    await commit("SET_EXPANDED_ANIM", undefined);
    await commit("SET_EXPANDED_CLIP", index);
    if (state.project_data.project_timeline[index]?.anim_custom_id != null) {
      const res = await axios.get(
        "/animations/" +
          state.project_data.project_timeline[index].anim_custom_id
      );
      commit("SET_EXPANDED_ANIM", res.data);
    }
  },

  // la prise enregister
  deleteClip: async ({ state, commit }, index) => {
    var clip_data = state.project_data.project_timeline[index];

    if (clip_data.clip_type == 1 && clip_data.media_id != null) {
      let response = await window.Toaster.prompt({
        title: I18n.t("Toaster.areYouSure"),
        message: I18n.t("Toaster.will_delete_media"),
        validate: I18n.t("Delete"),
        cancel: I18n.t("Undo"),
      });

      if (response != "validate") {
        return;
      }
    }

    commit("SAVING_MODE", true);

    return axios
      .delete(
        "/projects/" + state.project_data._id + "/timeline/" + clip_data._id
      )
      .then((res) => {
        commit("COLLAPSE_ALL_CLIPS");
        commit("SAVING_MODE", false);

        let newTimeline = state.project_data.project_timeline;
        newTimeline.splice(index, 1);

        commit("UPDATE_PROJECT", {
          project_timeline: newTimeline,
          project_duration: res.data.project_duration,
        });
        commit("SAVING_MODE", false);
      })
      .catch(() => {
        window.Toaster.error(I18n.t("CreativeSpace.error.deleteClip"));
      });
  },

  add_clip: async (
    { state, dispatch, commit },
    { index, type, expandAfter = true }
  ) => {
    await commit("SAVING_MODE", true);
    let data = {};

    var newClips;

    switch (type) {
      case "media":
        /*
        data = {
          clip_type: 1,
        };
        */
        const res = await axios.post(`/projects/${state.project_data._id}/timeline/${index}`, {
          clip_type: 1,
        })

        newClips = res.data

        break;

      case "intro":
      case "title":
      case "outro": {
        const anim_type_list = ["intro", "title", "outro"];
        const idx = anim_type_list.findIndex((elem) => elem == type);
        const anim_type = idx != -1 ? idx : null;
        data = {
          clip_type: 0,
          anim_type: anim_type,
          title_content:
            anim_type == 1
              ? I18n.t("ScriptPart.animation.titleAnimationPlaceHolder")
              : undefined,
        };

        const res = await axios.post(`/projects/${state.project_data._id}/timeline/${index}`, data)

        newClips = res.data

        break;
      }
      case "titleAndMedia":
        data = {
            clip_type: 0,
            anim_type: 1,
            title_content: I18n.t(
              "ScriptPart.animation.titleAnimationPlaceHolder"
            ),
            linked: true,
        };
        const res1 = await axios.post(`/projects/${state.project_data._id}/timeline/${index}`, data)
        newClips = [res1.data]
        const res2 = await axios.post(`/projects/${state.project_data._id}/timeline/${index}`, {clip_type: 1})
        newClips.push(res2.data)

        break;

      default:
        break;
    }

    commit("HIDE_ALL_ADDING_CLIP_DROPDOWS");
    await commit("ADD_CLIP", {
      index,
      clip: newClips
    });

    if (expandAfter) {
      dispatch("iAmExpanded", type == "titleAndMedia" ? index + 1 : index);
    }
    commit("SAVING_MODE", false);
  },
  addClipFromTranscription: async (
    { state, dispatch, commit },
    { clip_id_src, startWordIdx, endWordIdx }
  ) => {
    try {
      const index = state.project_data.project_timeline.findIndex(
        (t) => t._id == String(clip_id_src)
      );

      const res = await axios.post(
        `/projects/${state.project_data._id}/timeline/${index + 1}`,
        { clip_id_src, startWordIdx, endWordIdx }
      );

      await commit("ADD_CLIP", {
        index: index + 1,
        clip: res.data,
      });
      await dispatch("iAmExpanded", index + 1);
      commit("SAVING_MODE", false);
    } catch (e) {
      console.log(e);
    }
  },

  linkClip: ({ dispatch, state }, index) => {
    try {
      state.project_data.project_timeline[index].linked =
        !state.project_data.project_timeline[index].linked;

      dispatch("putProjectData", {
        project_timeline: state.project_data.project_timeline.map((c) => ({
          _id: c._id,
          linked: c.linked,
        })),
      });
    } catch {
      state.project_data.project_timeline[index].linked =
        !state.project_data.project_timeline[index].linked;
    }
  },

  init_new_project: ({ state, commit }) => {
    return axios({
      method: "post",
      url: `/projects/${state.project_data._id}/timeline/0`,
      data: [
        {
          clip_type: 0,
          title_content: "",
          anim_type: 0,
          linked: false,
          anim_custom_id: null,
        },
        {
          clip_type: 0,
          title_content: "",
          anim_type: 1,
          linked: true,
          anim_custom_id: null,
        },
        {
          clip_type: 1,
          scrpt_text: "",
        },
        {
          clip_type: 0,
          title_content: "",
          anim_type: 2,
          linked: false,
          anim_custom_id: null,
        },
      ],
    }).then((res) => {
      commit("UPDATE_PROJECT", {
        project_timeline: res.data.project_timeline,
        project_duration: res.data.project_duration,
      });
    });
  },

  refreshMedias: ({ state, commit, dispatch }, project_id) => {
    (async () => {
      //await sleep(5000)
      //WHILE PROJET LOADED
      while (state.project_loaded) {
        let res = await axios({
          method: "get",
          url: `/projects/${state.project_data._id}`,
        });
        //REFRESH PROJECT DURATION
        state.project_data.project_duration = res.data.project_duration;

        //REFRESH MEDIA_ID CHANGED
        state.project_data.project_timeline.flatMap((clip) => {
          //FIND CLIP IN RES DATA
          let clip_fetched = res.data.project_timeline.find(
            (c) => c._id === clip._id
          );

          //IF MEDIA_ID CHANGED OR MEDIA "READY"'s KEY CHANGED

          if (
            clip_fetched != null &&
            (clip_fetched.media_id != clip.media_id ||
              clip_fetched.ready != clip.ready)
          ) {
            (clip.media_id = clip_fetched.media_id),
              (clip.media = clip_fetched.media);
            clip.ready = clip_fetched.ready;

            //COMPLETE ONBOARDING TASK
            if (
              clip_fetched.media?.media_type == "video_record" &&
              clip_fetched.media.media_metadata?.mobile == false
            ) {
              dispatch("complete_onboarding_task", "record_w_webcam");
            } else if (
              clip_fetched.media?.media_type == "video_record" &&
              clip_fetched.media.media_metadata?.mobile == true
            ) {
              dispatch("complete_onboarding_task", "record_w_smartphone");
            } else if (clip_fetched.media?.media_type == "screen_record") {
              dispatch("complete_onboarding_task", "screen_record");
            }
          }

          return clip;
        });

        await sleep(5000);
      }
    })();
  },

  changeProjectLanguage : async ({ state, commit, dispatch }, langue) => {
    state.project_data.project_timeline.forEach(clip => {
      if (clip.clip_type == 1){
        clip.ready = false
      }
    })
    dispatch('putProjectData', {"project_language" : langue})
  },

  fetchClipData: async ({ state, commit }, clip_id) => {
    const project_id = state.project_data._id;
    const res = await axios.get('/projects/' + project_id + '/timeline/' + clip_id)
    commit('UPDATE_CLIP', res.data)

    return res.data
  }
};
