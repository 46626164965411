import axios from 'axios'

export default {

    loadVideos: ({ commit, rootState }, all) => {
        if (all == undefined) {
            all = false
        }
        let usr_id_request;
        if (rootState.auth.admin_usr_id_changer != null) {
            usr_id_request = rootState.auth.admin_usr_id_changer
        } else if (rootState.auth.user && rootState.auth.user._id) {
            usr_id_request = rootState.auth.user._id
        }

        if (all == true) {
            //GET ALL VIDEOS
            return axios
                .get("/videos/")
                .then((res) => {
                    commit('SET_VIDEOS', res.data)
                   // commit('VERIF_ERRORS', res.data.map(video => { return video._id }));

                    return res.data
                })
        } else if (usr_id_request != null) {
            return axios
                .get("/videos/", { params: { usr_id: usr_id_request } })
                .then((res) => {
                    commit('SET_VIDEOS', res.data)
                   // commit('VERIF_ERRORS', res.data.map(video => { return video._id }));

                    return res.data
                })
        }
    },
    getOneVideo: ({ commit }, data) => {
        return axios
            .get("/videos/" + data.id, {
                params: {
                    download_link : true,
                    ...data.query
                } })
            .then((res) => {
                commit('UPDATE', res.data)
                return res.data
            })
    },

    deleteVideo: ({ commit }, vdo_id) => {
        return axios.delete("/videos/" + vdo_id).then(() => {
            commit('DELETE', vdo_id)
        });
    },
    updateVideo: ({ commit }, data) => {
        return axios.put("/videos/" + data.id, data.body).then((res) => {
            commit('UPDATE', res.data)
        });
    }

};