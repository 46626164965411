export default {
  
  ADD_PROJECT: (state, data) => {
    state.projects.splice(0,0,data)

  },
  SET_PROJECTS: (state, projects) => {
    state.projects = projects;
  }, 
  PUT_PROJECT: (state, data) => {
    var res = (Array.isArray(data.res) == false && typeof (data.res) == "object")?[data.res]:data.res
  
    var collect_objs = data.collect_objs

    res.forEach((project) => {
      let index = state.projects.findIndex((video) => video._id == project._id)
      if(index != '-1'){
        for (let key in project) {
          state.projects[index][key] = project[key]
        }
        if (collect_objs != undefined) {
          for (let key in collect_objs) {
            state.projects[index][key] = collect_objs[key]
          }
        }
      }
    })
  },
  DELETE_PROJECT: (state, project_id) => {
    state.projects = state.projects.filter((project) => project._id != project_id);
  },
  UNSET_FIELD_PROJECT: (state, data) => {
    let index = state.projects.findIndex((project) => project[data.field] == data.value)
    while (index != "-1") {
      state.projects[index].ident_id = undefined;
      index = state.projects.findIndex((project) => project[data.field] == data.value)
    }

  },
 CLEAR_PROJECTS: (state)=>{
    state.projects = []
 }
 
}

