import Cookies from 'js-cookie'

const state = {
    onboarding_dashboard_visible : (()=>{
        let cookie = Cookies.get("onboarding_dashboard_visible");
        if (cookie != null) {
            return (cookie == "true" ? true : false)
        } else {
            return true;
        }
    })()
};

let onboarding_tasks = [
    {
        task_id : "create_visual_identity",
        description : null,
        video_url : "https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/onboarding_content/Creation+identity+visual.mp4"
    },
    {
        task_id : "select_animation",
        description : null,
        video_url : "https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/onboarding_content/Choose+an+animation.mp4"
    },
    {
        task_id : "record_w_webcam",
        description : null,
        video_url : "https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/onboarding_content/Take+a+shoot+webcam.mp4"
    },
    {
        task_id : "screen_record",
        description : null,
        video_url : "https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/onboarding_content/Capture+screen.mp4"
    },
    {
        task_id : "remove_silence",
        description : null,
        video_url : "https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/onboarding_content/removeSilence.mp4"
    },
    {
        task_id : "sound_enhancement",
        description : null,
        video_url : "https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/onboarding_content/soundEnhancement.mp4"
    },
    {
        task_id : "video_format",
        description : null,
        video_url : "https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/onboarding_content/videoFormat.mp4"
    },
    {
        task_id : "dynamic_caption",
        description : null,
        video_url : "https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/onboarding_content/dynamicCaptions.mp4"
    },
    {
        task_id : "sliceVideo",
        description : null,
        video_url : "https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/onboarding_content/sliceVideo.mp4"
    },
    {
        task_id : "remove_part_of_clip",
        description : null,
        video_url : "https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/onboarding_content/removePartInClip.mp4"
    },
    {
        task_id : "share_record_link",
        description : null,
        video_url : "https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/onboarding_content/recordLink.mp4"
    },
    {
        task_id : "add_overlay",
        description : null,
        video_url : "https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/onboarding_content/overlays.mp4"
    },
    {
        task_id : "change_caption_language",
        description : null,
        video_url : "https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/onboarding_content/translateVideo.mp4"
    }
]

const getters = {
    dashboard_visible: (state) => {
        return state.onboarding_dashboard_visible
        //return (getters.getCurrentUser.onboarding_data?.dashboard_visible != undefined ? getters.getCurrentUser.onboarding_data?.dashboard_visible : false)
    },
    onboarding_tasks: (state, getters) => {
        let tasks_completed = []
        if (getters.getCurrentUser?.onboarding_tasks_completed != undefined){
            tasks_completed = getters.getCurrentUser?.onboarding_tasks_completed
        }

        return onboarding_tasks.flatMap(task => {
            task.completed = tasks_completed.includes(task.task_id)
            
            task.htmlTippy = `
            <p style="padding:5px; width: 60vw">
                <video id="tippyVideo_${task.task_id}" webkit-playsinline="true"
                playsinline="true" src="${task.video_url}" autoplay muted="muted" loop></video>
                ${(()=>{
                    if (task.description != null){
                        return '<a style="margin-top:5px">' + task.description + '</a>'
                    } else {
                        return ''
                    }
                }
                )()}
            </p>
            `
            return task
        })
    },

    onboarding_tasks_completion: (state, getters) => {
        let tasks_completed = []
        if (getters.getCurrentUser?.onboarding_tasks_completed != undefined){
            tasks_completed = getters.getCurrentUser?.onboarding_tasks_completed
        }
        
        tasks_completed = tasks_completed.filter(task => onboarding_tasks.map(t => t.task_id).includes(task))
        
        return ((100*tasks_completed.length)/onboarding_tasks.length).toFixed(0)
    },

    remaining_tasks_count: (state, getters) => {
        let tasks_completed = [];

        if (getters.getCurrentUser?.onboarding_tasks_completed != undefined){
            tasks_completed = getters.getCurrentUser.onboarding_tasks_completed
        }

        tasks_completed = tasks_completed.filter(task => onboarding_tasks.map(t => t.task_id).includes(task))
        
        
        return onboarding_tasks.length - tasks_completed.length
    },
}

const actions = {
    hide_onboarding_dashboard: ({ state }) => {
        state.onboarding_dashboard_visible = false
        Cookies.set("onboarding_dashboard_visible", false, { expires: 365 });
    },
    show_onboarding_dashboard: ({ state }) => {
        state.onboarding_dashboard_visible = true
        Cookies.set("onboarding_dashboard_visible", true, { expires: 365 });
    },
    show_hide_onboarding_dashboard: ({ state }) => {
        state.onboarding_dashboard_visible = !state.onboarding_dashboard_visible
        Cookies.set("onboarding_dashboard_visible", state.onboarding_dashboard_visible, { expires: 365 });
    },
    complete_onboarding_task: ({ dispatch, rootState }, task_id) => {
        let onboarding_tasks_completed = rootState.auth?.user.onboarding_tasks_completed;

        if (!onboarding_tasks_completed.includes(task_id)){
            onboarding_tasks_completed.push(task_id)
            dispatch('updateCurrentUser', {
                onboarding_tasks_completed : onboarding_tasks_completed
            })
        }
    }
}

export default {
    state,
    getters,
    actions
  };