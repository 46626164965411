import i18n from '../../../i18n' 
const I18n = i18n.global

export default {
    project_loaded : false,
    project_data : {
        project_name : "",
        default_anims : {
            intro: null, 
            title: null,
            outro: null
        }
    },
    oldScript : [],
    part_expanded : -1,
    add_clip_dropdown_displayed : -1,
    dragging : false,
    catalogPanelVisible : false,
    autoSave: {
        status: 1,
        lastModification: new Date(),
        lastSave: "",
        action: undefined,
    },
    expanded_anim : undefined,
    toolBarOpen : false
}