import store from "./../store"

import profile_routes from './profile'
import admin_routes from './admin.js'

export default [
  {
    path: '/',
    name: 'inApp',
    meta: {
      title: 'App Yopbox - Accueil',
    },
    component: () => import('../views/inApp.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        meta: {
          title: 'App Yopbox - Accueil',
          requiresAuth : true
        },
        component: () => import('../views/Home/Home.vue')
      },
      {
        path: '/edit/:project_id',
        meta: {
          requiresAuth : true
        },
        component: () => import('../views/CreativeSpace/CreativeSpace.vue')
      },
      {
        path: '/videos',
        name: 'Mes vidéos',
        meta: {
          title: 'App Yopbox - Vidéothèque',
          requiresAuth : true
        },
        component: () => import('../views/VideoLibrary/VideoLibrary.vue'),
      },
      {
        path: '/identity',
        name: 'Chartes Graphiques',
        meta: {
          title: 'App Yopbox - Chartes Graphiques',
          requiresAuth : true
        },
        component: () => import('../views/Identity/Identity.vue'),
      },
  
      {
        path: '/settings',
        name: 'Default Settings',
        meta: {
          requiresAuth : true
        },
        component: () => import('../views/DefaultSettings/DefaultSettings.vue'),
      },
      
      {
        path: '/error',
        name: 'Erreur',
        component: () => import('../views/error.vue'),
      },
      ...profile_routes,
      ...admin_routes
    ]
  },
]