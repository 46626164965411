//LIBS
import verifGlobal from "../../../lib/verifGlobal"

export default {
 
  SET_VIDEOS: (state, videos) => {
    state.videos = videos;
  },
  DELETE: (state, vdo_id) => {
    state.videos = state.videos.filter((vdo) => vdo._id != vdo_id);
    state.selected_ids = state.selected_ids.filter((id_select) => id_select != vdo_id);

  },
  UPDATE: (state, vdo_list) => {
    
    if (vdo_list != undefined && typeof (vdo_list) != 'string' ) {
      //Transform object vdo in list (for feat with foreach function below)
      if (Array.isArray(vdo_list) == false && typeof (vdo_list) == "object") {
        vdo_list = [vdo_list]
      }

      var vdo_not_found = vdo_list

      vdo_list.forEach(vdo => {
        //Find vidéo in state
        state.videos.forEach((vdo_from_state) => {
          if (vdo_from_state._id == vdo._id) {

            //DELETE VDO FROM vdo_not_found LIST
            var index = vdo_not_found.findIndex(videoFrom_vdo_not_found_list => videoFrom_vdo_not_found_list._id == vdo_from_state._id);
            if (index != -1){
              vdo_not_found.splice(index,1);
            }

            //REPLACE AND UPDATE EACH KEY OF VIDEO IN STATE
            Object.keys(vdo).forEach(key => {
              vdo_from_state[key] = vdo[key]
            })
          }
        })
      })

      //IF VIDEO NOT FOUND => ADD VIDEO TO STATE
      if (vdo_not_found.length > 0) //console.debug('[STORE VIDEO MUTATION] vdo_not_found for update (adding it): ', vdo_not_found)
      state.videos.push(...vdo_not_found)
    }
  },
  VERIF_ERRORS: (state, list_id) => {
    state.vdo_errors = {
      vdo_name: [],
      vdo_timeline: [],
      ident_id: [],
      mood_id: [],
      global: [],
    };
    list_id.forEach((id) => {
      let index = state.videos.findIndex((vdo) => id == vdo._id);

      if (index != "-1") {
        let video = state.videos[index];


        if (video.vdo_name == "") {
          if (!state.vdo_errors.vdo_name.includes(video._id)) {
            state.vdo_errors.vdo_name.push(video._id);
          }

          state.vdo_errors.global.push(video._id);
        }


        if (video.vdo_duration == undefined || video.vdo_duration < 0) {
          if (!state.vdo_errors.vdo_timeline.includes(video._id)) {
            state.vdo_errors.vdo_timeline.push(video._id);
          }

          state.vdo_errors.global.push(video._id);
        }
        // Add timeline vérification

        if (verifGlobal.isEmpty(video.ident_id)) {
          if (!state.vdo_errors.ident_id.includes(video._id)) {
            state.vdo_errors.ident_id.push(video._id);
          }
          state.vdo_errors.global.push(video._id);
        }


        if (verifGlobal.isEmpty(video.mood_id)) {
          if (!state.vdo_errors.mood_id.includes(video._id)) {
            state.vdo_errors.mood_id.push(video._id);
          }
          state.vdo_errors.global.push(video._id);
        }
      }
    });
  },
  CLEAR_VIDEOS:  (state)=>{
    state.videos= [],
    state.projects= []
  
  }

}

