export default {
  UPDATE_PROJECT: (state, data) => {
    Object.keys(data).forEach((key) => {
      if (key == "project_options") {
        Object.keys(data.project_options).forEach((key2) => {
          state.project_data.project_options[key2] = data.project_options[key2];
        });
      } else {
        state.project_data[key] = data[key];
      }
    });
  },
  UPDATE_CLIP: (state, clip) => {
    var timeline = JSON.parse(JSON.stringify(state.project_data.project_timeline));
    const index = timeline.findIndex((c) => c._id == clip._id);
    
    Object.keys(clip).forEach((key) => {
      /*
      if (timeline[index][key] != clip[key]){
        console.log(timeline[index][key], "new", clip[key])
      }
      */
      //console.log('merging',key," => ", timeline[index][key], "with", clip[key])
      timeline[index][key] = clip[key];

    });
    state.project_data.project_timeline = timeline;
    
    state.project_data.project_duration = calc_project_duration(state.project_data.project_timeline);
  },
  ADD_CLIP: (state, data) => {
    if (Array.isArray(data.clip)) {
      state.project_data.project_timeline.splice(data.index, 0, ...data.clip);
    } else {
      state.project_data.project_timeline.splice(data.index, 0, data.clip);
    }

    state.project_data.project_duration = calc_project_duration(
      state.project_data.project_timeline
    );
  },
  CLEAR_PROJECT: (state) => {
    state.autoSave = {
      status: 1,
      lastModification: new Date(),
      lastSave: "",
      action: undefined,
    };
    state.project_data = {
      project_name: "",
      default_anims: {
        intro: null,
        title: null,
        outro: null,
      },
    };
    state.project_loaded = false;
    state.oldScript = [];
  },
  SET_EXPANDED_CLIP: (state, part_index) => {
    state.part_expanded = part_index;
  },
  COLLAPSE_ALL_CLIPS: (state) => {
    state.part_expanded = -1;
  },
  SET_EXPANDED_ANIM: (state, anim) => {
    state.expanded_anim = anim;
  },
  SET_PART_TIMELINE: (state, data) => {
    //RETRIEVE INDEX IF NOT GIVED
    if (data.index == undefined) {
      data.index = state.project_data.project_timeline.findIndex(
        (c) => c._id == data._id
      );
    }

    if (data.index == undefined || data.index == -1) {
      console.error("Can't update timeline, clip index not found");
      return;
    }

    //console.log("data : ", data);

    let part = state.project_data.project_timeline[data.index];
    Object.keys(data.set).forEach((key) => {
      part[key] = data.set[key];
    });
    state.project_data.project_timeline.splice(data.index, 1, part);
  },

  UPDATE_MOOD_DATA: (state, data) => {
    if (state.project_data) {
      state.project_data.mood = data;
    }
  },

  SAVING_MODE: (state, status) => {
    switch (status) {
      case true:
        state.autoSave.status = 0;
        //state.autoSave.lastModification = new Date();
        break;

      case false:
        state.autoSave.status = 1;
        state.autoSave.lastModification = new Date();
        break;
    }
  },

  SHOW_CATALOG: (state, status) => {
    state.catalogPanelVisible = status;
  },

  SET_DRAGGINIG_STATUS: (state, status) => {
    state.dragging = status;
  },

  SHOW_ADDING_CLIP_DROPDOWN: (state, position) => {
    state.add_clip_dropdown_displayed = position;
  },

  HIDE_ALL_ADDING_CLIP_DROPDOWS: (state) => {
    state.add_clip_dropdown_displayed = -1;
  },
};

function calc_project_duration(timeline) {
  let project_duration = 0;
  if (timeline != undefined) {
    timeline.forEach((clip) => {
      if (clip.clip_type == 0) {
        project_duration += clip.duration;
      } else if (clip.clip_type == 1) {
        if (clip.relative_duration == undefined) {
          project_duration += clip.estimated_duration;
        } else {
          project_duration += clip.relative_duration;
        }
      }
    });
  }

  return project_duration;
}
