

//NPM
import axios from 'axios'
import i18n from '../../../i18n' 
const I18n = i18n.global

const state = {
  timelineTemplate : []
}

const mutations = {
  SET_ALL_LIST: (state, list) => {
    state.timelineTemplate = list
  },
  UPDATE_ONE: (state, data) => {
    if (data._id != undefined){
      var found_index = null;
      state.timelineTemplate.forEach((timelineTemplate, index)=>{
        if (timelineTemplate._id == data._id){
            found_index = index
        }
      })
      if (found_index != null){
        state.timelineTemplate[found_index] = data
      }
    }
  },
  ADD_ONE : (state, data) => {
    state.timelineTemplate.push(data)
  },
  DELETE_ONE : (state, id) => {
    if (id != undefined){
      var found_index = null;
      state.timelineTemplate.forEach((timelineTemplate, index)=>{
        if (timelineTemplate._id == id){
            found_index = index
        }
      })
      if (found_index != null){
        state.timelineTemplate.splice(found_index, 1)
      }
    }
  },
  CLEAR_TEMPLATES: (state)=>{
    state.timelineTemplate = []
  }
}

const getters = {
  timelineTemplates: state => state.timelineTemplate
}

const actions = {
  fetch_timeline_template : ({ commit }) =>{
    //RETREIVE LOCALE
    const locale = (I18n.locale.value == "fr" ? 'fr' : 'en')
    return axios.get(`/templates?language=${locale}`)
    .then((res)=>{
        commit('SET_ALL_LIST', res.data)
    })
    .catch(err =>{
        console.error(err.response.data)
    })
  },
  create_timeline_template : ({ commit }, template) =>{
    return axios.post('/templates/', template)
    .then((res)=>{
        var created_template = res.data
        //console.debug(created_template)
        commit('ADD_ONE', created_template)
        return ("[TimelineTemplate Store] Successful creation")
    })
    .catch(err =>{
        console.error("[TimelineTemplate Store] ",err.response.data)
    })
  },
  update_timeline_template : ({ commit }, template) =>{
    return axios.put('/templates/' + template._id, template)
    .then((res)=>{
        var template_updated = res.data
        //console.debug("Store update timeline res : ", template_updated)
        commit('UPDATE_ONE', template_updated)
        return ("[TimelineTemplate Store] Successful update")
    })
    .catch(err =>{
        console.error("[TimelineTemplate Store] ",err.response.data)
    })
  },
  delete_timeline_template : ({ commit }, _id) =>{
    return axios.delete('/templates/' + _id)
    .then((res)=>{
        //console.debug("Store delete timeline res : ", template_updated)
        commit('DELETE_ONE', _id)
        return ("[TimelineTemplate Store] Successful deletion ")
    })
    .catch(err =>{
        console.error("[TimelineTemplate Store] ",err)
    })
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}