import i18n from '../../../i18n' 
const I18n = i18n.global

export default {
    isProjectLoaded: (state) => {
        return state.project_data._id != (null || undefined)
    },
    moodLabel: (state, getters) => {
        if (getters.isProjectLoaded && state.project_data.mood != undefined){
            return I18n.locale.value == "fr" || state.project_data.mood.mood_nameTraduct == undefined ? state.project_data.mood.mood_name : state.project_data.mood.mood_nameTraduct[I18n.locale.value];
        } else {
            return I18n.t("CreativeSpace.chooseMood")
        }
    },

    toolBarIsOpen: (state) => {
        return state.toolBarOpen
    },

    //Aggregate parts linked in same element in array (for dragging all together) 
    script_w_group_linked_parts: (state) => {
        if (state.project_data.project_timeline != undefined){


        return state.project_data.project_timeline
            .flatMap((part, index, array)=>{

                //SAVE POSITION
                part.position = index

                //IF LINKED (WITH NEXT) AND HASN'T A GROUP DRIVER => PART WILL BECOME GROUP DRIVER
                if (part.linked == true && part.group_driver == undefined && index != array.length - 1){
                    //TRANSMIT GROUP DRIVER TO NEXT PART
                    array[index+1].group_driver = index

                    return {
                        _id : part._id,
                        group: true,
                        list : [part]
                    }
                
                //IF LINKED (WITH NEXT) AND HAS GROUP DRIVER
                } else if (part.linked == true && part.group_driver != undefined){ 
                    //TRANSMIT GROUP DRIVER TO NEXT PART
                    array[index + 1].group_driver = part.group_driver;
                    return part
                } else {
                    return part
                }
            }).flatMap((part, index, array)=>{
                //PUSH TO LIST OF GROUP DRIVER
                if (part.group_driver != undefined && array[part.group_driver]?.list){
                    array[part.group_driver].list.push(part)
                    return null
                } else {
                    return part
                }
            }).filter(e => e != null)
        } else {
            return []
        }
    },

    //am I expanded ?
    amIExpanded: (state) => (index) => {
        if (state.part_expanded == index && state.dragging == false){
            return true
        } else {
            return false
        }
    },

    clip_data: (state) => (index) => {
        if (typeof index == 'number' && state.project_data.project_timeline != undefined){
            return state.project_data.project_timeline[index]
        } else {
            return {}
        }
    },

    linkedWithPrevious: (state, getters) => (index) => {
        if (index == 0){
            return false
        } else {
            return getters.clip_data(index-1).linked
        }
    },

    //Is my "add clip dropdown" is displayed ?
    add_clip_dropdown_displayed: (state) => (index) => {
        return state.add_clip_dropdown_displayed == index
    },

    isTheLastPart: (state) => (index) => {
        if (typeof index == 'number'){
            return index == state.project_data.project_timeline.length - 1
        } else {
            return false
        }
    },

    dragging: (state) =>{
        return state.dragging
    },

    isCatalogPanelVisible: (state) =>{
        return state.catalogPanelVisible
    },

    default_anims : (state) =>{
        if (state.project_data && state.project_data.default_anims != undefined ){
            return state.project_data.default_anims
        } else {
            return {
                intro: null,
                title: null,
                outro: null,
              }
        }
    },

    identity : ( state, getters ) => {
        if (getters.isProjectLoaded && state.project_data.identity){
            return state.project_data.identity
        } else {
            return {
                ident_title: null,
                ident_colors: null,
                ident_font: null,
                ident_watermark: null
            }
        }
    },

    moodId : ( state, getters ) => {
        if (getters.isProjectLoaded && state.project_data.mood && state.project_data.mood._id){
            return state.project_data.mood._id
        } else {
            return null
        }
    },

    project_options : ( state ) => {
        return state.project_data?.project_options || {}
    },

    in_saving_mode : ( state ) => {
        return state.autoSave.status == 0
    },

}