import store from "./../store";
export default [
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile/Profile.vue"),
    /*
    beforeEnter: (to, from, next) => {
      if (to.path == "/profile" || to.path == "/profile/") {
        next({
          name: "Profile.info",
        });
      } else {
        next();
      }
    },
    */
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "information",
        name: "Profile.info",
        component: () => import("../views/Profile/subViews/information.vue"),
      },
      {
        path: "password",
        name: "Profile.pwd",
        component: () => import("../views/Profile/subViews/password.vue"),
      },
      {
        path: "subscriptions",
        name: "Profile.subscriptions",
        component: () =>
          import("../views/Profile/subViews/subscriptions/subscriptions.vue"),
      },

      {
        path: "team",
        name: "Profile.team",
        component: () => import("../views/Profile/subViews/team.vue"),
        beforeEnter: async (to, from, next) => {
          if (
            store.getters["getCurrentUser"].team == undefined ||
            store.getters["getCurrentUser"].team.role != 0
          ) {
            next({ path: "/" });
          } else {
            next();
          }
        },
      },
      {
        path: "notifications",
        name: "Profile.notifications",
        component: () => import("../views/Profile/subViews/Notifications.vue"),
      },
    ],
  },
];
