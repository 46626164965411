<template>
  <div class="router">
    <router-view class="router-view"></router-view>

    <Toaster />

    <span v-if="show_app_version" class="app_version">{{app_version}}</span>
    
  </div>
</template>

<script>
// LIBS
import axios from "axios";
import { Crisp } from "crisp-sdk-web";
import Hotjar from '@hotjar/browser';

//COMPONENTS
import {default as navigationVersion} from "../src/lib/navigatorVersion"

export default {
  name: "App Yopbox",
  metaInfo: {
    title: "App Yopbox",
  },
  data() {
    return {
      click_location: "home",
    };
  },
  computed : {
    show_app_version(){
      return this.$route.path == "/login"
    }
  },
  created(){
    var r = document.querySelector(':root');
    r.style.setProperty('--color1', this.domain_customization.color1);
    r.style.setProperty('--color2', this.domain_customization.color2);
    
    if (process.env.domain_name == "convinceo"){
      r.style.setProperty('--color_font', "black");
      r.style.setProperty('--color_gradient_start', "var(--color1)");
    }

  },
  mounted() {
    global.jQuery = require("jquery");
    var $ = global.jQuery;
    window.$ = $;

    axios.interceptors.request.use(
      (data) => {
        return data;
      },
      (err) => {
        console.log(err);
      }
    );
    
    var meta1 = document.createElement('meta');
    meta1.httpEquiv = "apple-mobile-web-app-status-bar-style";
    meta1.content = "black-translucent";
    document.getElementsByTagName('head')[0].appendChild(meta1);

    var meta2 = document.createElement('meta');
    meta2.httpEquiv = "apple-touch-fullscreen";
    meta2.content = "YES";
    document.getElementsByTagName('head')[0].appendChild(meta2);

    if (navigationVersion.mobile == true){
      document.querySelector('meta[name="viewport"]').setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no");
    }

    
    if (process.env.NODE_ENV == "production") {
      //CRIPS CHAT
      Crisp.configure("7a65eacd-78fc-49c6-83ea-36b83d778c8c");
      
      //ADD FIRST PROMOTER SCRIPT 1
      var FP_1_s = document.createElement("script");
      const FP_TextNode = document.createTextNode(`
      (function(w){w.fpr=w.fpr||function(){w.fpr.q = w.fpr.q||[];w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);};})(window);
      fpr("init", {cid:"rukscbrq"}); 
      fpr("click");
      `);
      FP_1_s.appendChild(FP_TextNode);
      document.getElementsByTagName("head")[0].appendChild(FP_1_s);
  
      //ADD FIRST PROMOTER SCRIPT 2
      var FP_2_s = document.createElement("script");
      FP_2_s.src = "https://cdn.firstpromoter.com/fpr.js";
      FP_2_s.async = 1;
      document.getElementsByTagName("head")[0].appendChild(FP_2_s);


      //HOTJAR
      const siteId = 3520917;
      const hotjarVersion = 6;
      Hotjar.init(siteId, hotjarVersion);

      //ADD HEAD GOOGLE ANALYICS
      var GA_s = document.createElement("script");
      GA_s.src = "https://www.googletagmanager.com/gtag/js?id=G-ER9J0SLK9X";
      GA_s.async = 1;
      document.getElementsByTagName("head")[0].appendChild(GA_s);

      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag("js", new Date());
      gtag("config", "G-ER9J0SLK9X");

    }
  },
};
</script>

<style src="./styles/global.css">
</style>

<style src="./styles/inputs.css">
</style>
<style src="./styles/loading_spinner.css">
</style>

<style src="./styles/buttons.scss" lang="scss">
</style>

<style src="./styles/cards.scss" lang="scss">
</style>

<style src="./styles/panel.css">
</style>

<style src="./styles/checkbox.css">
</style>

<style src="./styles/trash.css">
</style>

<style src="./styles/transitions.css">
</style>

<style lang="css" scoped>
.app_version{
  position: absolute;
  right: 10px;
  left : auto;
  bottom: 10px;
}

.router-view {
  position: absolute;
  height: 100%;
  left: 0px;
  width: 100%;
}
</style>
